<template>
  <MultiSearchTableAdmin
      v-if="isAdmin"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :createData="createData"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
  />

  <MultiSearchTableUser
      v-else
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :createData="createData"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
  />
</template>

<script>
  import MultiSearchTableAdmin from "./MultiSearchTableAdmin/MultiSearchTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import MultiSearchTableUser from "./MultiSearchTableUser/MultiSearchTableUser";

  export default {
    name: "MultiSearchTable",

    components: {
      MultiSearchTableUser,
      MultiSearchTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getMultiSearchForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterUserAdminId: '',
        filterUserAdminName: '',
        filterTrackingNumber: '',
        filterCreatedAt: [],
        filterShippingDate: [],
        filterCloseDate: [],

        filterReasonId: '',
        filterReasonName: '',
        filterStatusId: '',
        filterStatusName: '',
        filterShippingCompanyId: '',
        filterShippingCompanyName: '',
        filterProcessId: '',
        filterProcessName: '',

        countFilterParams: 0,
        filterGetParams: {},

        createData: null,
      }
    },

    mounted() {
      this.filter()
    },

    //fix for a situation where user makes another search from the search page
    beforeRouteUpdate (to, from, next) {
      console.log(111, to, from);
      console.log(222, next);
      if (from?.params?.query !== to?.params?.query) {
        //need to call next, otherwise it will use the prev url (our query is not a GET param)
        next()
        this.filter()
      }
      else if (JSON.stringify(from.query) !== JSON.stringify(to.query)) {
        next()
      }
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          userAdminId: this.$route.query.userAdminId,
          userAdminName: this.$route.query.userAdminName,
          trackingNumber: this.$route.query.trackingNumber,
          createdAt: this.$route.query.createdAt,
          shippingDate: this.$route.query.shippingDate,
          closeDate: this.$route.query.closeDate,

          reasonId: this.$route.query.reasonId,
          reasonName: this.$route.query.reasonName,
          statusId: this.$route.query.statusId,
          statusName: this.$route.query.statusName,
          shippingCompanyId: this.$route.query.shippingCompanyId,
          shippingCompanyName: this.$route.query.shippingCompanyName,
          processId: this.$route.query.processId,
          processName: this.$route.query.processName,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextMultiSearchPage', true)
        let queryType = 'fetchMultiSearch' //this.isAdmin ? 'fetchMultiSearch' : 'fetchMultiSearchLocal'
        this.$store.dispatch(queryType, {filter: url, query: this.$route.params.query}).then(() => {
          this.$store.commit('setNextMultiSearchPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })

        this.checkCountFilter(['userName', 'userAdminName', 'reasonName', 'statusName', 'shippingCompanyName', 'processName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()
        console.log(this.filterProcessId);
        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'id',
            filterUserId: 'user_id',
            filterUserAdminId: 'admin_user_id',
            filterTrackingNumber: 'tracking_number',

            filterReasonId: 'reason',
            filterStatusId: 'status',
            filterProcessId: 'status_result',
            filterShippingCompanyId: 'delivery_service_id',
          },
        )

        if (this.filterCreatedAt.length > 0) {
          let date = this.generateDateForFilter(this.filterCreatedAt, 'YYYY-MM-DD')
          myQuery.whereIn('createdAt', date)
        }

        if (this.filterShippingDate.length > 0) {
          let date = this.generateDateForFilter(this.filterShippingDate, 'YYYY-MM-DD')
          myQuery.whereIn('shippingDate', date)
        }

        if (this.filterCloseDate.length > 0) {
          let date = this.generateDateForFilter(this.filterCloseDate, 'YYYY-MM-DD')
          myQuery.whereIn('closeDate', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    },

  }
</script>

<style scoped>

</style>
