<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
              :name="$t('RequestReturn_Return.localization_value.value')"
          :value="'#180378'"
          :backgroundImage="'product-admin'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_BackReturns',
                  ])"></span>
                <router-link :to="$store.getters.GET_PATHS.financeRequestReturn" class="order-create__head-back">
                  <LinkBack
                          :value="$t('RequestReturn_BackReturns.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_RequestReturn',
                  ])"></span>
              {{$t('RequestReturn_RequestReturn.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_PleaseFill',
                  ])"></span>
              {{$t('RequestReturn_PleaseFill.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_OrderDeatils',
                  ])"></span>
              {{$t('RequestReturn_OrderDeatils.localization_value.value')}}
            </div>
            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_LotNumber',
                  ])"></span>
                <DefaultInput
                    :label="$t('RequestReturn_LotNumber.localization_value.value')"
                    :errorTxt="'This field is required'"
                    v-model="requestReturn.lotNumber"
                    :disabled="true"
                />
              </div>
              <div class="custom-col custom-col--33 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_Transaction',
                  ])"></span>
                <DefaultInput
                    :label="$t('RequestReturn_Transaction.localization_value.value')"
                    :errorTxt="'This field is required'"
                    v-model="requestReturn.transactionNumber"
                    :disabled="true"
                />
              </div>
            </div>

            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_ReturnsDetails',
                  ])"></span>
              {{$t('RequestReturn_ReturnsDetails.localization_value.value')}}
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--xs-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_RefundTotaly',
                  ])"></span>
                <RadioDefault
                    :label="$t('RequestReturn_RefundTotaly.localization_value.value')"
                    :name="'insuranceRadio'"
                    @change.native="changeRadio(returnTypes.totally)"
                    :value="requestReturn.returnType === returnTypes.totally"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--xs-100 flex-column mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_RefundPartially',
                    'RequestReturn_RefundSum',
                  ])"></span>
                <RadioDefault
                    :label="$t('RequestReturn_RefundPartially.localization_value.value')"
                    :name="'insuranceRadio'"
                    @change.native="changeRadio(returnTypes.partially)"
                    :value="requestReturn.returnType === returnTypes.partially"
                />

                <div class="custom-row mt-4" >
                  <div class="custom-col custom-col--50">
                    <InputSum
                        v-model="requestReturn.refundPartially"
                        :numeric="true"
                        :label="$t('RequestReturn_RefundSum.localization_value.value')"
                        :icoType="'dollar'"
                        :placeholder="'0'"
                        :disabled="requestReturn.returnType === returnTypes.totally"
                        v-bind:class="{'ui-no-valid': validation.amount,
                        'disabled-opacity' : requestReturn.returnType === returnTypes.totally}"
                        :error="validation.amount"
                        :errorTxt="serverError ? validationTxt.amount : $t(`${validationTranslate.amount}.localization_value.value`)"
                    />
                  </div>
                </div>
              </div>
            </div>


            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'requestReturn_Comment',
                  ])"></span>
              {{$t('requestReturn_Comment.localization_value.value')}}
            </div>

            <div class="order-create__row custom-row"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'requestReturn_Comment',
                  ])"></span>
              <div class="order-create__col custom-col custom-col--66 custom-col--md-100">
                <TextareaDefault
                    :label="$t('requestReturn_Comment.localization_value.value')"
                    v-model="requestReturn.comment"
                    v-bind:class="{'ui-no-valid': validation.comment}"
                    :error="validation.comment"
                    :errorTxt="serverError ? validationTxt.comment : $t(`${validationTranslate.comment}.localization_value.value`)"
                    :disabled="true"
                />
              </div>
            </div>

            <!--<div class="custom-row">-->
              <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
                   <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                  <!--<span class="admin-edit" @click="editTranslate([-->
                    <!--'RequestReturn_FeeRefund',-->
                  <!--])"></span>-->
                <!--<InputSum-->
                        <!--:label="$t('RequestReturn_FeeRefund.localization_value.value')"-->
                        <!--:numeric="true"-->
                        <!--:icoType="'dollar'"-->
                        <!--:placeholder="'0'"-->
                        <!--v-model="requestReturn.refundFee"-->
                        <!--v-bind:class="{'ui-no-valid': validation.refundFee,-->
                        <!--'disabled-opacity' : requestReturn.returnType === returnTypes.totally}"-->
                        <!--:error="validation.refundFee"-->
                        <!--:errorTxt="serverError ? validationTxt.refundFee : $t(`${validationTranslate.refundFee}.localization_value.value`)"-->
                <!--/>-->
              <!--</div>-->
              <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
                   <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                  <!--<span class="admin-edit" @click="editTranslate([-->
                    <!--'requestReturn_Status',-->
                  <!--])"></span>-->
                <!--<DefaultSelect-->
                        <!--:options="optionsStatus"-->
                        <!--:label="$t('requestReturn_Status.localization_value.value')"-->
                        <!--:optionsLabel="'type'"-->
                        <!--:selected="requestReturn.statusSelected"-->
                        <!--v-bind:class="{'ui-no-valid': validation.statusSelected}"-->
                        <!--:error="validation.statusSelected"-->
                        <!--:errorTxt="serverError ? validationTxt.statusSelected : $t(`${validationTranslate.statusSelected}.localization_value.value`)"-->
                        <!--@change="changeStatusSelected"-->
                <!--/>-->
              <!--</div>-->
            <!--</div>-->

            <div class="custom-row"
                 v-if="Object.hasOwnProperty.call($store.getters.getRequestReturnItem, 'parent_payment_transaction') &&
                 $store.getters.getRequestReturnItem.parent_payment_transaction.payment_system == PAYMENT_TYPE_NAME.payPall &&
                  $store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_WriteOff',
                  ])"></span>
                <DefaultCheckbox
                        class="wfc"
                        :label="$t('RequestReturn_WriteOff.localization_value.value')"
                        v-model="requestReturn.withApi"
                />
              </div>
            </div>

          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <!--<div class="order-create__footer-link"-->
                 <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                  <!--<span class="admin-edit" @click="editTranslate([-->
                    <!--'common_cancel',-->
                  <!--])"></span>-->
              <!--<router-link :to="$store.getters.GET_PATHS.financeRequestReturn"-->
                           <!--class="site-link site-link&#45;&#45;alt"-->
              <!--&gt;-->
                <!--{{ $t('common_cancel.localization_value.value') }}-->
              <!--</router-link>-->
            <!--</div>-->

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'cuspayments_requestRefund',
                    'RequestReturn_CancelRefund',
                  ])"></span>

              <MainButton
                  v-if="!isAdmin && Object.hasOwnProperty.call($store.getters.getRequestReturnItem, 'parent_payment_transaction') &&
                    $store.getters.getRequestReturnItem.parent_payment_transaction.payment_system == PAYMENT_TYPE_NAME.authorize"
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="createReturns(true)"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{ $t('common_drafts.localization_value.value') }}</b>
                  </p>
                  <p>
                    {{ $t('common_savingInDrafts.localization_value.value') }}
                  </p>
                </template>
              </MainButton>

              <MainButton
                      v-else-if="_.has($store.getters.getRequestReturnItem, 'parent_payment_transaction') &&
                      $store.getters.getRequestReturnItem.parent_payment_transaction.canMakeRefund"
                      class="order-create__footer-btn-i wfc"
                      :value="$t('cuspayments_requestRefund.localization_value.value')"
                      @click.native="createReturns"
                      v-bind:class="{'disabled-btn' : $store.getters.getRequestReturnBtn}"
              />

              <!--<MainButton-->
                      <!--v-if="requestReturn.statusSelected == CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS"-->
                      <!--class="order-create__footer-btn-i wfc"-->
                      <!--:value="$t('RequestReturn_CancelRefund.localization_value.value')"-->
                      <!--@click.native="createReturns"-->
              <!--/>-->
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput.vue";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum.vue";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {RequestReturn} from "../variebles";
  import {validation} from "../../../../../../services/validation";
  // import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {CUSTOMERS_PAYMENTS_STATUSES, PAYMENT_TYPE_NAME} from "../../../../../../staticData/staticVariables";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {boolToStringFunc} from "../../../../../../mixins/commonMixins/boolToStringFunc";
  import {requestReturnSocketMixin} from "../../../../../../mixins/socketMixins/socketMixin";


  export default {
    name: "RequestReturnEditingUser",

    components: {
      DefaultCheckbox,
      // DefaultSelect,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      DefaultInput,
      InputSum,
      RadioDefault,
      TextareaDefault,
    },

    mixins: [boolToStringFunc, requestReturnSocketMixin],

    data() {
      return{

        typeReturns: 'new',

        types: {
          new: 'new',
          withPayments: 'withPayments',
          draft: 'draft',
        },

        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,

        optionsStatus: [
          {
            id: 1,
            type: CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS
          },
          {
            id: 2,
            type: CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS
          },
        ],

        returnTypes: {
          totally: 'totally',
          partially: 'partially',
        },

        requestReturn: new RequestReturn(),

        serverError: false,
        validation: {},
        validationTranslate: {},
        validationTxt: {},

      }
    },

    created() {
      this.requestReturn.setReturnType(this.returnTypes.totally)

      this.validation = this.requestReturn.getValidation()
      this.validationTranslate = this.requestReturn.getValidationTranslate()
      this.validationTxt = this.requestReturn.getValidationTxt()
    },

    mounted() {
      if(this.$route.params.id) {
        this.typeReturns = this.types.withPayments

        this.$store.dispatch('getRequestReturn', this.$route.params.id).then(() => {
          let getRequestReturnItem = this.$store.getters.getRequestReturnItem

          this.redirectToNotFound(getRequestReturnItem.in_draft === 1)

          this.requestReturn.refundPartially = getRequestReturnItem.amount

          this.requestReturn.withApi = getRequestReturnItem.with_api == '0' ? true : false

          this.requestReturn.lotNumber = this.showLotNumber(getRequestReturnItem)
          this.requestReturn.comment = getRequestReturnItem.comment ? getRequestReturnItem.comment : ''

          this.requestReturn.returnType = getRequestReturnItem.refund_type === this.returnTypes.totally ?
            this.returnTypes.totally : this.returnTypes.partially

          this.requestReturn.transactionNumber = getRequestReturnItem.parent_payment_transaction_id +
            " : $" + getRequestReturnItem.amount
        })
      }
    },

    methods:{

      changeStatusSelected(val) {
        this.requestReturn.statusSelected = val.type
      },

      showLotNumber(payment){
        if(payment.parent_payment_transaction.payment_transaction_detail.l_number0 !== null){
          return payment.parent_payment_transaction.payment_transaction_detail.l_number0
        } else if (payment.parent_payment_transaction.payment_transaction_detail.invoice_number !== null) {
          return payment.parent_payment_transaction.payment_transaction_detail.invoice_number
        } else {
          return '-'
        }
      },

      changeRadio(val){
        let getRequestReturnItem = this.$store.getters.getRequestReturnItem
        this.requestReturn.refundPartially = getRequestReturnItem.parent_payment_transaction.refundAmount
        this.requestReturn.setReturnType(val)
      },

      createReturns() {
        let validationItems = {
          // statusSelected : this.requestReturn.statusSelected,
        }

        let validationOptions = {
          // statusSelected: {type: ['empty']},
        }

        if(this.requestReturn.returnType === this.returnTypes.partially){
          validationItems.amount = Number(this.requestReturn.refundPartially.replace(',', ''))
          validationOptions.amount = {type: ['empty', 'not-zero']}
        }

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError;

        if (validate.isValidate) {
          this.serverError = false;

          let data = {
            "refund_type" : this.requestReturn.returnType,
            "amount" : this.requestReturn.returnType === this.returnTypes.partially ?
              this.requestReturn.refundPartially.replace(',', '') :
              this.$store.getters.getRequestReturnItem.parent_payment_transaction.payment_total,
            // "refundFee" : this.requestReturn.refundFee,
            "withApi" : this.requestReturn.withApi ? '0' : '1',
            // "status" : this.requestReturn.statusSelected,
          }

          let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestReturn')

          if(prepareSocket.active) {
            this.$store.commit('setRequestReturnSocket', true)
            this.createRequestReturnSocket(prepareSocket)
          }

          this.$store.dispatch('changeRequestReturn', {id: this.$route.params.id, data: data}).then((response) => {

            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.openNotify('success', 'common_notificationRecordCreated')
              this.$router.push(this.$store.getters.GET_PATHS.financeRequestReturn)

            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;

              errors.comment ? (this.validation.comment = true, this.validationTxt.comment = errors.comment[0]) : false;
            }

          })

        }

      },

      // createReturns() {
      //   console.log(234);
      // },
    }
  }
</script>

<style lang="scss">

  .hide-upc{
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps{
    max-width: 625px;
  }

</style>
